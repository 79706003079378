import { Data, TileAction } from "@/views/shared/dashboard/dashboard.interface";;
import { getColumns as notRoofedCareReceivers } from "./not_roofed_care_receivers";


export const getTableSuffix = (
  data: Data
) => {
  return ''
  /*const contextActions = data.contextActions
  return contextActions.assign_seller?.usecase
    ?? contextActions.memberDuplicates?.usecase
      ?? ''*/
}

export const getColumns = (
  data: Data | any[],
  tileAction: TileAction,
  next: Function
) => {
  const url = tileAction.href
  switch (url) {
    case '/care_receivers/not_roofed':
      return notRoofedCareReceivers(data)
    default:
      throw Error("This Tile Action does not exist.")
  }
}
